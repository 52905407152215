<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4">
          <b-media
            v-if="userInfo"
            vertical-align="center"
            class="align-items-center"
          >
            <template #aside>
              <b-avatar
                size="120"
                :src="userInfo.avatar"
                :text="avatarText(userInfo.name)"
                :variant="`light-primary`"
              />
            </template>
            <p class="font-weight-bold d-block text-nowrap">
              {{ nameComplete }}
            </p>
            <small class="text-muted">@{{ userInfo.name }}</small>
          </b-media>
        </b-col>
        <b-col md="8">
          <div class="d-flex">
            <h3 class="text-center w-100">Score</h3>
            <GoBack class="text-left" />
          </div>
          <b-progress
            show-progress
            class="mt-2 rounded-lg roundedCustom"
            :max="totalScore"
            height="3rem"
            :variant="getVariantType(totalScoreUser)"
          >
            <b-progress-bar
              :value="totalScoreUser"
              :label="`${((totalScoreUser / totalScore) * 100).toFixed(1)}%`"
            ></b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-table
          :items="userItem"
          :fields="fields"
          class="position-relative"
          responsive
          show-empty
          empty-text="No matching records found"
          bordered
        >
          <template #cell(pivot)="data">
            <b-form-group v-slot="{}">
              <div class="d-flex">
                <b-form-radio-group
                  value-field="item"
                  text-field="name"
                  :id="`radio-group-${data.index}`"
                  v-model="selected[data.index]"
                  disabled
                >
                  <b-form-radio
                    v-for="(option, index) in scorePerItem[data.index]"
                    :key="index"
                    :id="`radio-${data.index}-${index}`"
                    :value="option.item"
                    :name="`radio-group-${data.index}`"
                    v-b-tooltip.hover.top="option.hover"
                  >
                    {{ option.name }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </b-form-group>
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BProgress,
  BProgressBar,
  BBadge,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  VBTooltip,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

import axiosS from "@/services/admin/goals/goalScore";
import GoBack from "@/components/ButtonBack.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BProgress,
    BProgressBar,
    BBadge,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    avatarText,
    GoBack,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.getUserInfo();
  },
  data() {
    return {
      userInfo: null,
      userId: JSON.parse(localStorage.getItem("userData")),
      isAvalible: this.$ability.can("index", "users"),
      userItem: null,
      fields: [
        { key: "name", sortable: true },
        { key: "description", sortable: true },
        { key: "pivot", label: "Score", sortable: true },
      ],
      scoreHover: {
        Puntualidad: [
          "Tiene mas de 5 llegadas tarde o errores al marcar",
          "Tiene 4 llegadas tarde o errores al marcar",
          "Tiene 3 llegadas tarde o errores al marcar",
          "Tiene 1-2 llegadas tarde o errores al marcar",
          "No tiene llegadas tarde",
        ],
        ["Cumplimiento de obligaciones"]: [
          "No cumple en 4 ocasiones",
          "No cumple en 3 ocasiones",
          "No cumple en 2 ocasiones",
          "No cumple en 1 ocasión",
          "Cumple satisfactoriamente",
        ],
        ["Desempeño Individual"]: [
          "No cumple en 4 ocasiones",
          "No cumple en 3 ocasiones",
          "No cumple en 2 ocasiones",
          "No cumple en 1 ocasión",
          "Cumple satisfactoriamente",
        ],
        ["Desempeño con el paciente"]: [
          "Tiene 4 reviews",
          "Tiene 3 reviews",
          "Tiene 2 reviews",
          "Tiene 1 review",
          "No tiene reviews de 1 a 3 estrellas",
        ],
      },
      totalScore: 0,
      totalScoreUser: 0,
      scorePerItem: {},
      selected: {},
    };
  },
  computed: {
    nameComplete() {
      return `${this.userInfo.name} ${this.userInfo.lastname}`.toUpperCase();
    },
  },
  methods: {
    getUserInfo() {
      axiosS.goalUserInfo(this.userId.id).then(({ registro }) => {
        const { objectifiable } = registro;
        this.userInfo = registro;
        this.userItem = objectifiable;
        const data = [];
        objectifiable.forEach((item, index) => {
          this.totalScoreUser += item.pivot.score;
          this.totalScore += item.pivot.goal;

          const { pivot } = item;
          this.selected[index] = pivot.score;
          const itemData = [];
          for (let i = 0; i <= pivot.goal; i++) {
            itemData.push({
              item: i,
              hover: this.scoreHover[item.name]?.[i] ?? "",
              name: i,
            });
          }
          data.push(itemData);
        });
        this.scorePerItem = data;
      });
    },
    avatarText(name) {
      return avatarText(name);
    },
    getVariantType(item) {
      if (item >= 20) return "success";
      if (item >= 14) return "warning";
      if (item >= 1) return "danger";
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.progress .progress-bar:last-child {
  border-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
