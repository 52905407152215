import { userAxios } from '@/services'

const goalUserInfo = async (userId, data) => {
    try {
        return await userAxios.get(`goal_user/score/view/${userId}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalUserUpdate = async (userId, data) => {
    try {
        return await userAxios.put(`goal_user/score/update/${userId}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalUserFilter = async (data, perPage, page) => {
    try {
        return await userAxios.post(`goal_user/score/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    goalUserUpdate,
    goalUserInfo,
    goalUserFilter
}